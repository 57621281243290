import React from "react"

import { Container } from "react-bootstrap"

interface MyJumboProps {
  title: string
  content: React.ReactElement
}
const MyJumbo: React.FC<MyJumboProps> = (props) => {
  const { title, content } = props
  return (
    <div className="jumbotron" style={myjumbo_styles}>
      <div>
        <h1 className="mb-3">{title}</h1>
        <div className="mt-2">{content}</div>
      </div>
    </div>
  )
}

const myjumbo_styles: React.CSSProperties = {
  backgroundColor: "#fff",
  marginBottom: 0,
  padding: "4rem 2rem",
}

export default MyJumbo
