import React from "react"
import FormatHtml from "../components/utils/FormatHtml"
import { graphql, Link } from "gatsby"
import { BlogPostBySlugQuery, MarkdownRemarkFrontmatter } from "../graphql"
import { Pages, pageDescriptions } from "../pages"
import Layout from "../components/layout/Layout"
import { Jumbotron, Button } from "react-bootstrap"
import MyJumbo from "../components/myJumbo/MyJumbo"
import { Image } from "../components/Seo.d"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()
import "./blog-template.css"

interface MyButtonProps {
  disabled: boolean
  to: string
}
const MyButton: React.FC<MyButtonProps> = ({ disabled, to, children }) => {
  if (!disabled) {
    return (
      <Button
        variant="dark"
        disabled={disabled}
        as={Link}
        to={to}
        style={{
          pointerEvents: disabled ? "none" : undefined,
        }}
      >
        {children}
      </Button>
    )
  } else {
    return (
      <Button variant="dark" disabled={disabled}>
        {children}
      </Button>
    )
  }
}

interface Post {
  html: React.ReactNode
  fields: {
    slug: string
  }
  frontmatter: MarkdownRemarkFrontmatter
}

interface Props {
  data: BlogPostBySlugQuery
  pageContext: {
    slug: string
    next: Post
    previous: Post
  }
}

const BlogTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const post = data?.markdownRemark
  const frontMatter = data.markdownRemark?.frontmatter
  const title = frontMatter?.title || ""
  const subtitle = frontMatter?.subtitle || ""

  const previousDisabled = !pageContext.previous
  const nextDisabled = !pageContext.next

  const fluid = frontMatter?.cover?.childImageSharp?.fluid

  const blogCover: Image = {
    src: fluid?.src || "",
    height: 500,
    width: 500,
  }

  return (
    <Layout page={Pages.BLOG} seoTitle={title} seoImage={blogCover}>
      <MyJumbo
        title={title}
        content={
          <>
            <Button
              variant="dark"
              as={Link}
              to={pageDescriptions[Pages.BLOG].address}
            >
              Go Back
            </Button>
            <h2>{subtitle}</h2>
          </>
        }
      />
      <FormatHtml content={post?.html} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MyButton
          disabled={previousDisabled}
          to={`${pageContext?.previous?.fields?.slug}`}
        >
          Previous
        </MyButton>
        <div className="mx-3" />
        <MyButton
          disabled={nextDisabled}
          to={`${pageContext?.next?.fields?.slug}`}
        >
          Next
        </MyButton>
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        cover {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
